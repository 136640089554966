
import * as React from "react"

interface GenericPost {
  author: string
  createdAt: string
}

const PostMetadataDisplay = ({ post, className = "" }: { post: GenericPost, className?: string }) => {
  return (
    <p className={className}>{
      new Date(parseInt(post.createdAt)).toLocaleString(
        "en-US", { month: 'long', day: 'numeric', year: 'numeric' }
      )
    }{post.author && ` - ${post.author}`}</p>
  )
}

export default PostMetadataDisplay

