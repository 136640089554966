
import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import PostMetadataDisplay from '../../components/metadata'
import { SEO } from '../../components/seo'

const NewsPage = ({ data, location }) => {
  return (
    <Layout location={location}>
      <h1>News</h1>
      <div>
        {
          data.allContentfulNewsPost.edges
            .sort((a,b) => b.node.createdAt - a.node.createdAt)
            .map((edge, i) =>
              <div key={i}>
                <h3 className="mb-0"><Link to={edge.node.newsPostPath}>{edge.node.title}</Link></h3>
                <PostMetadataDisplay post={edge.node} />
              </div>
          )
        }
      </div>
    </Layout>
  )
}

export const query = graphql`
  query NewsPageNewsPostsQuery {
    allContentfulNewsPost {
      edges {
        node {
          author
          createdAt(formatString: "x")
          title
          id
          newsPostPath: gatsbyPath(filePath: "/news/{contentfulNewsPost.title}")
        }
      }
    }
  }
`

export default NewsPage

export const Head = () => (
  <SEO title="Calvary News" />
)

